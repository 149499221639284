/**
 * Mobile Menu Sub menu toggles
 */

export function mobileMenuToggle() {
	$(".cp-header__mobile__menu").on("click", "a", function(e) {
		var _this = $(this);
		if (_this.siblings(".sub-menu").length > 0) {
			e.preventDefault();
			e.stopPropagation();
			_this
				.siblings(".sub-menu")
				.stop(true, true)
				.slideToggle(200)
				.closest(".menu-item")
				.toggleClass("menu__is-open");
		}
	});
}
