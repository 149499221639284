import "lity";
import { fitVidsBlocks } from "./../util/fitVids";
import { mobileMenuToggle } from "./../util/mobileMenu";

import AOS from 'aos';

export default {
	init() {
		// JavaScript to be fired on all pages
		mobileMenuToggle();
		// fitVidsBlocks();

		AOS.init({
			offset: 150,
			delay: 150,
			once: true,
			disable: function () {
				var maxWidth = 768;
				return window.innerWidth < maxWidth;
			}
		});

		$(window).on('resize', function(){
			AOS.refresh();
		});

		// ----- label only menu items; no click
		$('.label-only > a').on('click',function(e){
			e.preventDefault();
		});

		// ----- put the Solutions menu inside the main menu
		let solutions_main_menu = '.solutions-mega-menu';
		let solutions_menu_cont = '.solutions-menu-container';
		let solutions_menu_markup = $(solutions_menu_cont).html();
		$(solutions_main_menu + ' > a').after(solutions_menu_markup);

		let solutions_mobile_menu = '.solutions-mobile-menu';
		let solutions_mobile_cont = '.solutions-menu';
		let solutions_mobile_markup = $(solutions_mobile_cont).html();
		$(solutions_mobile_menu + ' > a').after(solutions_mobile_markup);
		// ----- end

		// ----- footer menu sub menu show hide 
		$('.tds-footer-expand').on('click',function(){
			$(this).siblings('.sub-menu').slideToggle();
		});
		// ----- end

		// ----- show/hide the mobile menu
		$('.j-mobile-trigger').on('click',function(){
			$('.tds-mobile').toggleClass('active');
			$('body').toggleClass('no-overflow');
		});
		// ----- end

		// ----- mobile menu sub menu show hide 
		$('.solutions-svg').on('click',function(){
			$(this).siblings('.solutions-sub-menu').slideToggle();
		});
		// ----- end

		// ----- sticky menu 
		var initScroll = $(window).scrollTop();
		var scrollTarget = $('.tds-header');
		var scrollDistance = 175;

		if ( initScroll >= scrollDistance ) {
			scrollTarget.addClass('sticky');
		}

		$(window).scroll(function(){
			var userScroll = $(window).scrollTop();
			if ( userScroll >= scrollDistance ) {
				scrollTarget.addClass('sticky');
			} else if ( userScroll < scrollDistance ) {
				scrollTarget.removeClass('sticky');
			}
		});
		// ----- end

		// ----- sticky menu solutions menu behaviour
		$('.solutions-mega-menu').hover(
			function() {
				$('.tds-header.sticky').addClass('mega-menu-active');
			}, function() {
				$('.tds-header.sticky').removeClass('mega-menu-active');
			}
		);



	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
